import React from 'react'
import PropTypes from 'prop-types'
import Logo from "../images/svg-icons/codeengine-mark.svg";

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <Logo />
        </div>
        <div className="content">
            <div className="inner">
                <h1>CODE ENGINE</h1>
                <p>Software Architecture &amp; Custom Application Development</p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>About</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
