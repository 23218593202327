import React from 'react';
import PropTypes from 'prop-types';

import Img from "gatsby-image"

class Main extends React.Component {
  render() {

    const { data } = this.props;
    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Dave Maple</h2>
          <Img fixed={data.file.childImageSharp.fixed} alt="" style={{maxWidth: '250px', borderRadius: '100%', textAlign: 'center', margin: '0 auto'}}/>
          <br /><br />
          <p>
            Dave Maple is an experienced software architect and application artisan with 15+ years of experience delivering solutions for companies of all sizes.
          </p>
          <p>
            As a solo contributor, his skills are extensive including AWS solutions architecture and software development in a multitude of languages including Java, Scala, Kotlin, PHP, NodeJS/Javascript, Python and Go.
          </p>
          <p>
            Dave has also served as an executive for multiple companies with positions including President, Chief Revenue Officer, and Chief Technology Officer.
            He understands the bottom line and delivers cost-effective, strategic solutions.
            He is a proven leader who builds strong teams by promoting inspiration and competition while achieving the strategic goals of the organization.
          </p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact Dave Maple</h2>

          <Img fixed={data.file.childImageSharp.fixed} alt="" style={{maxWidth: '250px', borderRadius: '100%', textAlign: 'center', margin: '0 auto'}}/>
          <br />

          <p>
            By Phone: <a href="tel:+16039886588">(603) 988-6588</a><br />
            By Email: <a href="mailto:dave.maple@codeengine.com">dave.maple@codeengine.com</a><br /><br />
          </p>

          <ul className="icons">
            <li><a href="https://www.linkedin.com/in/davemaple/" className="icon fa-linkedin"><span className="label">LinkedIn</span></a></li>
            <li><a href="skype:dave.maple" className="icon fa-skype"><span className="label">Skype</span></a></li>
            <li><a href="https://github.com/davemaple" className="icon fa-github"><span className="label">GitHub</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  data: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
};

export default Main;